const SDPHelpers = require('./sdp_helpers.js');
const env = require('../../helpers/env.js');

const profiles = {
  h264: {
    baseline: '42001f',
    constBaseline: '42e01f',
  },
};

const replaceProfileId = ({ sdp, from, to }) => sdp.replace(
  new RegExp(`^(a=fmtp:\\d+ .*profile-level-id=)${from}`, 'gmi'),
  `$1${to}`
);

module.exports = {
  local: {
    offer: (
      {
        numSimulcastStreams,
        audioBitrate,
        priorityVideoCodec = '',
        codecFlags = {},
        enableStereo = false,
        replaceBaselineProfile = false,
      } = {},
      sdpParam
    ) => {
      // The SDP may have excessive or stray SSRCs.  For example, SSRCs not
      // associated with a SIM group.  We remove these before we munge, else
      // the offer may grow too large.
      // See OPENTOK-41253
      let sdp = SDPHelpers.filterExcessSSRCs(sdpParam);

      if (numSimulcastStreams > 1) {
        sdp = SDPHelpers.enableSimulcast(sdp, numSimulcastStreams);
      }

      if (audioBitrate > 0) {
        sdp = SDPHelpers.setAudioBitrate(sdp, audioBitrate);
      }

      sdp = SDPHelpers.modifyReceiveStereo(sdp, false);
      sdp = SDPHelpers.modifySendStereo(sdp, Boolean(enableStereo));

      if (!env.isLegacyEdge) {
        Object.keys(codecFlags)
          .filter(codec => codecFlags[codec] === false)
          .forEach((codec) => {
            sdp = SDPHelpers.removeVideoCodec(sdp, codec);
          });
      }

      if (priorityVideoCodec && !env.isLegacyEdge) {
        sdp = SDPHelpers.reprioritizeVideoCodec(sdp, priorityVideoCodec, 'top');
      } else if (priorityVideoCodec !== 'h264') {
        sdp = SDPHelpers.reprioritizeVideoCodec(sdp, 'h264', 'bottom');
      }

      let remoteSdp = sdp;
      if (replaceBaselineProfile) {
        remoteSdp = replaceProfileId({
          sdp: remoteSdp,
          from: profiles.h264.baseline,
          to: profiles.h264.constBaseline,
        });
      }

      return {
        local: sdp,
        remote: remoteSdp,
      };
    },
    answer: (
      {
        audioBitrate,
        codecFlags,
        enableStereo = false,
        replaceBaselineProfile = false,
      } = {},
      sdpParam
    ) => {
      let sdp = sdpParam;

      if (audioBitrate > 0) {
        sdp = SDPHelpers.setAudioBitrate(sdp, audioBitrate);
      }

      sdp = SDPHelpers.modifyReceiveStereo(sdp, Boolean(enableStereo));
      sdp = SDPHelpers.modifySendStereo(sdp, false);

      if (codecFlags) {
        Object.keys(codecFlags)
          .filter(codec => codecFlags[codec] === false)
          .forEach((codec) => {
            sdp = SDPHelpers.removeVideoCodec(sdp, codec);
          });
      }

      let remoteSdp = sdp;
      if (replaceBaselineProfile) {
        remoteSdp = replaceProfileId({
          sdp: remoteSdp,
          from: profiles.h264.baseline,
          to: profiles.h264.constBaseline,
        });
      }

      return {
        local: sdp,
        remote: remoteSdp,
      };
    },
  },
  remote: {
    offer: ({ replaceBaselineProfile = false }, sdpParam) => {
      let sdp = sdpParam;

      if (sdp.indexOf('a=rtcp-fb') === -1) {
        const rtcpFbLine = 'a=rtcp-fb:* ccm fir\r\na=rtcp-fb:* nack ';
        sdp = sdp.replace(/^m=video(.*)$/gmi, `m=video$1\r\n${rtcpFbLine}`);
      }

      if (replaceBaselineProfile) {
        sdp = replaceProfileId({
          sdp,
          from: profiles.h264.constBaseline,
          to: profiles.h264.baseline,
        });
      }

      return {
        local: sdp,
      };
    },
    answer: ({ audioBitrate, replaceBaselineProfile = false } = {}, sdpParam) => {
      let sdp = sdpParam;

      if (audioBitrate > 0) {
        sdp = SDPHelpers.setAudioBitrate(sdp, audioBitrate);
      }

      if (replaceBaselineProfile) {
        sdp = replaceProfileId({
          sdp,
          from: profiles.h264.constBaseline,
          to: profiles.h264.baseline,
        });
      }

      return {
        local: sdp,
      };
    },
  },
};
